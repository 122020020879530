
import { defineComponent } from "vue";
import store from "@/store";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import PhysService from "@/services/PhysService";
import { InvLoc, Location } from "@/types/state/inventory";
import BinItem from "@/types/binItem";
import { AxiosError } from "axios";
import Phys from "@/types/phys";
import PlanGroupItem from "@/types/plangroup";
import Dialog from "primevue/dialog"
import Utils from "@/utility/utils";

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    Button,
    Dialog,
  },
  data() {
    return {
      displayConfirmation: false,
      loading: false,
      loadingImage: false,
      binRequired: false,
      loadingPart: false,
      isPartSubmitted: false,
      isLocationSubmitted: false,
      hasLot: false,
      onFocus: false,
      isSubmitted: false,
      hideUM: true,
      loadUser: false,
      selectedUser: this.getUser(),
      hideDescription: false,
      barcode: "",
      desc: "",
      cardHeight: "h-20rem",
      notes: "",
      selectedPart: "",
      transferPart: "",
      part: "",
      getPart: {
        number: "",
      },
      lot: "",
      image: "",
      loadSubmit: false,
      loadAddPart: false,
      isHidden: false,
      itemDesc: null,
      partNum: "",
      change_notes: "",
      layout: "list",
      quantity: "1",
      bin: "",
      bins: [] as Array<BinItem>,
      planGroups: [] as Array<PlanGroupItem>,
      description: "",
      lastPart: "",
      showClear: false,
      dataViewQuantity: 1,
      loadingFromLocations: false,
      um: "",
      successCounter: 0,
      successChecker: false,
      errorChecker: false,
      errorCounter: 0,
      location: "",
      placeholder: "",
      lotControl: false,
      toBitArr: [0, 0, 0],
      fromBitArr: [0, 0, 0],
      loadingLocations: false,
      validCount: false,
      locations: [] as Array<Location>,
      fromLocations: [] as Array<InvLoc>,
      newFromLocations: [] as Array<Location>,
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono p-input-icon-right",
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      selectedPart: {
        required: helpers.withMessage("Part Required", required),
      },
      quantity: {
        minLength: minLength(1),
      },
      bin: {
        location: {
          required: helpers.withMessage("Lot Required", required),
        },
      },
      lot: {
        location: {
          required: helpers.withMessage("Bin Required", required),
        },
      },
      location: {
        required: helpers.withMessage("Location Required", required),
      },
    };
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      addNotification: "notification/add",
      fetchLocations: "inventory/fetchLocations",
      fetchPartFromInventoryStore: "inventory/fetchPart",
      fetchInventory: "inventory/fetchInventory",
    }),
    /*
     * @desc checks if a location is bin controlled
     * @desc and checks if the part is lot controlled
     * @desc if either of them are true then the respective inputtext is enabled
     */
    LocationSelected(location: string, isFrom: boolean) {
      const loc = this.getLocation(location);
      const part = this.getPartFromInventoryStore;
      const isControlled = loc && loc.bin_control && loc.bin_control == "Y";
      if (isControlled) {
        this.cardHeight = "h-23rem";
      }
      this.$nextTick(() => {
        this.focusBin();
      });
    },
    getUser() {
      this.loadUser = true;
      if (this.selectedUser == "") {
        this.showClear = false;
        this.loadUser = false;
      } else {
        this.showClear = true;
        this.loadUser = false;
        return (store.getters["session/getUserId"] as string).toUpperCase();
      }
    },
    async fetchPartFromPartStore() {
      this.selectedPart = Utils.validateScanInput(this.selectedPart, true);

      this.isPartSubmitted = true;
      this.um = "";
      const isPartNotEmpty = await this.v$.selectedPart.$validate();
      if (isPartNotEmpty) {
        this.loadingPart = true;
        this.hideDescription = true;
        this.fetchPartFromInventoryStore({
          client: store.getters["session/getClient"],
          id: this.selectedPart,
          correls: "um",
        })
          .then(() => {
            this.fetchInventoryDetails();
            this.hideDescription = false;
            this.hideUM = false;
            this.um = this.getPartFromInventoryStore.um;
            this.selectedPart = this.getPartFromInventoryStore.part_no;
            const part = this.getPartFromInventoryStore;
            if (part.lot_control && part.lot_control == "Y") {
              this.cardHeight = "h-23rem";
              this.hasLot = true;
              this.focusLot();
            } else {
              this.focusQuantity();
            }
            this.loadingPart = false;

            this.checkPart(this.selectedPart, this.location, this.bin);
          })
          .catch(() => {
            this.loadingPart = false;
            this.hideDescription = true;
            this.hideUM = true;
            this.um = "";
            (this.$refs.part as any).$el.select();
          });
      }
    },
    fetchInventoryDetails() {
      this.loadingFromLocations = true;
      this.newFromLocations = [];
      this.fetchInventory({
        client: store.getters["session/getClient"],
        id: this.selectedPart,
        correls: "um",
      })
      .then(() => {
        this.loadingFromLocations = false;
      })
      .catch(() => { this.loadingFromLocations = false });
    },
    async submit() {
      this.loadSubmit = true;
      const service = new PhysService(process.env.VUE_APP_ABSTRACTION_API);

      const physCount: Phys = {
        part: this.selectedPart,
        invloc: this.location,
        bin_no: this.bin,
        lot_no: this.lot,
        count_qty: this.quantity,
        count_init: this.selectedUser as string,
      };

      service
        .postRecount(physCount)
        .then((res) => {
          const notification = {
            type: "success",
            message: "Success",
          };
          this.addNotification(notification, { root: true });
          this.loadSubmit = false;
          if(this.getBinItems.length > 0) {
            this.focusBin();
          } else {
            this.focusPart();
          }
          this.clear();
        })
        .catch((error: AxiosError) => {
          this.loadSubmit = false;
        });
    },
    clear() {
      this.lastPart = this.selectedPart;
      this.selectedPart = "";
      this.hideUM = true;
      this.hasLot = false;
      this.quantity = "1";
      this.isPartSubmitted = false;
      this.isSubmitted = false;
      this.lot = "";
      this.bin = "";
      this.um = "";
    },
    fetchBins() {
      if (this.getBinItems == 0) {
        const control = {
          Client: store.getters["session/getClient"],
          id: "BIN",
          procedure: "BIN.CONTROL",
          filename: "CONTROL",
        };
        this.fetchControl(control)
          .then(() => {
            this.bins = this.getBinItems;
          })
          .catch(() => {
            const notification = {
              type: "error",
              message: "Failed to load bins",
            };
            this.addNotification(notification);
          });
      } else {
        this.bins = this.getBinItems;
      }
    },
    checkPart(part: string, location: string, bin: string) {
      const service = new PhysService(process.env.VUE_APP_ABSTRACTION_API);

      service
        .checkPart(part, location, bin, "Y")
        .then((res: any) => {
          if(res?.phys_items?.length > 0) {
            this.displayConfirmation = true;
          } 
        })
        .catch((error: AxiosError) => {
          this.loadSubmit = false;
        });
    },
    addConfirmation() {
      this.displayConfirmation = false;
      this.focusQuantity();
    },
    closeConfirmation() {
      this.clear();
      this.displayConfirmation = false;
      this.focusLocation();
    },
    fetchInvPlanData() {
      if (this.getInvPlanGroupItems == 0) {
        const control = {
          Client: store.getters["session/getClient"],
          id: "INV",
          procedure: "INV.CONTROL",
          filename: "CONTROL",
        };
        this.fetchControl(control)
          .then(() => {
            this.planGroups = this.getInvPlanGroupItems;
          })
          .catch(() => {
            const notification = {
              type: "error",
              message: "Failed to load inventory plan.",
            };
            this.addNotification(notification);
          });
      } else {
        this.planGroups = this.getInvPlanGroupItems;
      }

      const plans = this.planGroups.filter(
        (groups) => groups.physical_date != null
      );

      if (plans.length > 0) {
        this.validCount = true;
      }
    },
    focusLocation() {
      (this.$refs.location as any).$el.focus();
    },
    focusPart() {
      (this.$refs.part as any).$el.focus();
    },
    focusBin() {
      (this.$refs.bin as any).$el.focus();
    },
    focusLot() {
      (this.$refs.lot as any).$el.focus();
    },
    focusQuantity() {
      (this.$refs.quantity as any).$el.focus();
      (this.$refs.quantity as any).$el.select();
    },
    handleLocation() {
      this.binRequired = false;
      const loc = this.locations.find(
        (loc) => loc.invloc_id.toLowerCase() == this.location.toLowerCase()
      );

      if (loc != null) {
        const validPlanGroup = this.planGroups.some(
          (groups) => groups.plan_group == loc.plan_group
        );

        if (validPlanGroup) {
          this.location = loc.invloc_id;

          if (loc.bin_control == "Y") {
            this.binRequired = true;
            this.focusBin();
          } else {
            this.focusPart();
          }
        } else {
          const notification = {
            type: "error",
            message: "Location is not included in this physical.",
          };
          this.addNotification(notification);
          (this.$refs.location as any).$el.select();
        }
      } else {
        const notification = {
          type: "error",
          message: "Location not valid.",
        };
        this.addNotification(notification);
        (this.$refs.location as any).$el.select();
      }
    },
    handleBin() {
      if (this.binRequired) {
        const bin = this.bins.find(
          (bin: BinItem) =>
            bin.valid_bins.toLowerCase() == this.bin.toLowerCase()
        );

        if (bin != null) {
          this.bin = bin.valid_bins;
          this.focusPart();
        } else {
          const notification = {
            type: "error",
            message: "Bin not valid.",
          };
          this.addNotification(notification);
          (this.$refs.bin as any).$el.select();
        }
      }
    },
    handleLot() {
      this.focusQuantity();
    },
  },
  computed: {
    ...mapState(["inventory"]),
    ...mapGetters({
      getLocations: "inventory/getLocations",
      getBinItems: "control/getBinItems",
      getInvPlanGroupItems: "control/getInvPlanGroupItems",
      getPartFromInventoryStore: "inventory/getPart",
      getLocation: "inventory/getLocation",
      getPartLocation: "inventory/getPartLocation",
    }),
  },
  async created() {
    this.fetchInvPlanData();
    if (this.getLocations == null) {
      try {
        this.loadingLocations = true;
        const response = this.fetchLocations({
          client: store.getters["session/getClient"],
        });
        if (await response) {
          this.locations = this.getLocations as Array<Location>;
          this.locations = this.locations.map((item: Location) => {
            const label = `${item.invloc_id} - ${item.desc}`;
            return { ...item, label };
          }) as Array<Location>;
          this.loadingLocations = false;
        }
      } catch (error) {
        const notification = {
          type: "error",
          message: error,
        };
        this.addNotification(notification);
      }
    } else {
      this.locations = this.getLocations as Array<Location>;
      this.locations = this.locations.map((item: Location) => {
        const label = `${item.invloc_id} - ${item.desc}`;
        return { ...item, label };
      }) as Array<Location>;
    }
    this.getUser();
    this.fetchBins();
  },
  mounted() {
    this.focusLocation();
  },
});
